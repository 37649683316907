import Breadcrumbs from 'aac-components/components/Breadcrumbs';
import Byline from '../Byline';
import VobApp from 'aac-components/components/VobApp';
import {BREAKPOINT} from 'aac-components/utils/styles';
import MaxWidth from '../MaxWidth';
import MedicallyReviewed from '../MedicallyReviewed';
import styled from 'styled-components';
import Image from 'next/image';
import {useContext} from 'react';
import AppContext from '../AppContext';
import HiddenOnDesktop from 'aac-components/components/HiddenOnDesktop';
import PagePills from '../PagePills';
import WhatYouWillLearn from '../WhatYouWillLearn';
import VobAbandonModal from 'aac-components/components/VobApp/VobAbandonModal';

const MultiStepVobHero = (props) => {
    const {
        data: {
            id = 0,
            title: {rendered: title = ''} = {},
            byline_info = {},
            modified = '',
            acf: {
                'rehab-guide-learn': highlights = [],
                'rehab-guide-summary': summary = '',
            } = {},
        },
    } = props;

    const {isMobile} = useContext(AppContext);

    const useFeaturedImage =
        props?.data?.featured_image && props.data.featured_image.length > 0;

    const imageSource = useFeaturedImage
        ? props?.data?.featured_image[props?.data?.featured_image.length - 1]
        : '/static/aac_hero_bg_desktop.jpg';

    return (
        <TextStyled useFeaturedImage={useFeaturedImage}>
            <div className="hero">
                {!isMobile && (
                    <div className="hero__image-container">
                        <Image
                            src={imageSource}
                            priority={true}
                            fill
                            style={{objectFit: 'cover', background: 'var(--primary-400)'}}
                            alt="American Addiction Centers Photo"
                            sizes="100vw"
                        />
                        {useFeaturedImage && <div className="overlay"></div>}
                    </div>
                )}
                <MaxWidth>
                    {!isMobile && (
                        <div className="hero__breadcrumbs">
                            <Breadcrumbs IconFill={useFeaturedImage ? 'white' : ''} />
                        </div>
                    )}
                    <div className="hero__content--container">
                        <div
                            className="hero__content--main"
                            style={
                                useFeaturedImage ? {color: 'white'} : {color: 'black'}
                            }>
                            {isMobile && (
                                <HiddenOnDesktop>
                                    <Image
                                        src={imageSource}
                                        priority={true}
                                        fill
                                        style={{objectFit: 'cover'}}
                                        alt="American Addiction Centers Photo"
                                    />
                                    {useFeaturedImage && <div className="overlay"></div>}
                                </HiddenOnDesktop>
                            )}
                            <div style={{position: 'relative'}}>
                                {isMobile && (
                                    <div className="hero__breadcrumbs">
                                        <Breadcrumbs />
                                    </div>
                                )}
                                <MedicallyReviewed bylineData={byline_info} />
                                <h1 dangerouslySetInnerHTML={{__html: title}} />
                                <Byline
                                    contributors={byline_info}
                                    lastModified={modified}
                                />
                                <div className="hero__pills">
                                    <PagePills />
                                </div>
                                {summary && (
                                    <div
                                        className="hero__copy"
                                        dangerouslySetInnerHTML={{__html: summary}}
                                    />
                                )}
                                {highlights && (
                                    <div className="hero__highlights">
                                        <WhatYouWillLearn highlights={highlights} />
                                    </div>
                                )}
                                {!isMobile && (
                                    <div className="hero__vob-copy">
                                        <div>
                                            <p>
                                                <strong>
                                                    Instantly find your coverage
                                                    information.
                                                </strong>
                                            </p>
                                            <p>
                                                We'll be able to tell you if your
                                                insurance provider is in network with an
                                                American Addiction Centers treatment
                                                facility.
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="hero__content--vob">
                            {isMobile && (
                                <div className="hero__vob-copy">
                                    <div>
                                        <p>
                                            <strong>
                                                Instantly find your coverage information.
                                            </strong>
                                        </p>
                                        <p>
                                            We'll be able to tell you if your insurance
                                            provider is in network with an American
                                            Addiction Centers treatment facility.
                                        </p>
                                    </div>
                                </div>
                            )}
                            <VobStyles useFeaturedImage={useFeaturedImage}>
                                <VobAbandonModal>
                                    <VobApp
                                        automationTestingSelector="vob-hero"
                                        type="multi-step"
                                    />
                                </VobAbandonModal>
                            </VobStyles>
                        </div>
                    </div>
                </MaxWidth>
            </div>
            <style jsx>
                {`
                    h1 {
                        margin-bottom: 8px;
                    }
                    .overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(
                            0,
                            0,
                            0,
                            0.5
                        ); /* Dark overlay with 50% opacity */
                    }
                    .hero {
                        position: relative;
                        min-width: 100%;
                        padding: 0;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .hero {
                            padding: 24px 0 42px 0;
                            margin-bottom: 40px;
                        }
                    }
                    .hero__breadcrumbs {
                        position: relative;
                        margin-bottom: 8px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .hero__breadcrumbs {
                            margin-bottom: 50px;
                        }
                    }
                    .hero__content--container {
                        display: grid;
                        grid-template-columns: 1fr;
                        font-size: 16px;
                        position: relative;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .hero__content--container {
                            grid-template-columns: 2fr 1fr;
                            grid-gap: 150px;
                        }
                    }
                    .hero__copy {
                        margin-bottom: 16px;
                        line-height: 28px;
                    }
                    .hero__content--main {
                        position: relative;
                        padding: 8px 15px 24px 15px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .hero__content--main {
                            padding: 0;
                            margin-bottom: 0;
                        }
                    }
                    .hero__content--vob {
                        padding: 0 15px 30px 15px;
                        margin-bottom: 40px;
                        background: var(--primary-100);
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .hero__content--vob {
                            padding: 0;
                            margin-bottom: 0;
                            background: none;
                        }
                    }
                `}
            </style>
        </TextStyled>
    );
};
export default MultiStepVobHero;

const VobStyles = styled.div`
    @media screen and (min-width: ${BREAKPOINT}) {
        width: 400px;
    }
    .vob-ms {
        border: ${(props) => (props.useFeaturedImage ? '' : '1px solid var(--gray-300)')};
        border-radius: 8px;
    }
`;

const TextStyled = styled.div`
    .breadcrumbs__li a,
    .breadcrumbs__text,
    .breadcrumbs__slash,
    .c-byline__text,
    .c-byline__entry {
        color: ${(props) => (props.useFeaturedImage ? 'white !important' : '')};
    }
`;
