import IconCheck from 'aac-components/components/Icons/Basic/IconCheck';

const WhatYouWillLearn = ({highlights = []}) => {
    return (
        <div className="container">
            {highlights && highlights.length >= 1 && (
                <div className="text-sm bold" style={{margin: '0 0 15px 0'}}>
                    What you will learn:
                </div>
            )}
            {Array.isArray(highlights) &&
                highlights.map((item, index) => {
                    return (
                        <div key={index} className="item">
                            <IconCheck fill={'var(--success)'} />
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: item.highlight,
                                }}
                                style={{marginLeft: '15px'}}
                            />
                        </div>
                    );
                })}
            <style jsx>
                {`
                    .item {
                        display: grid;
                        grid-template-columns: 20px auto;
                        align-items: center;
                        font-size: 14px;
                        line-height: 20px;
                        margin-bottom: 8px;
                    }
                `}
            </style>
        </div>
    );
};
export default WhatYouWillLearn;
