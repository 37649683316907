import Breadcrumbs from 'aac-components/components/Breadcrumbs';
import Byline from '../Byline';
import {BREAKPOINT, MAX_WIDTH_PX} from 'aac-components/utils/styles';
import styled from 'styled-components';
import {getArticleReadTime, getHeadings} from '../../lib/utils';
import MedicallyReviewed from '../MedicallyReviewed';
import PagePills from '../PagePills';
import WhatYouWillLearn from '../WhatYouWillLearn';
const ArticleHero = ({data = {}}) => {
    const {
        content: {rendered: content = ''} = {},
        title: {rendered: title = ''} = {},
        byline_info = {},
        modified = '',
        acf: {
            'rehab-guide-learn': highlights = [],
            'rehab-guide-summary': summary = '',
        } = {},
    } = data;

    const headings = getHeadings(content);

    return (
        <div className="hero">
            <div className="hero__inner">
                <BreadcrumbsStyles>
                    <Breadcrumbs />
                </BreadcrumbsStyles>
                <MedicallyReviewed bylineData={byline_info} />
                <h1 dangerouslySetInnerHTML={{__html: title}} />

                <Byline contributors={byline_info} lastModified={modified} />

                <div className="hero__readtime">
                    <span>{getArticleReadTime(content)} min read</span>
                    {Array.isArray(headings) && (
                        <span> · {headings?.length} sections</span>
                    )}
                </div>
                <div className="hero__pills">
                    <PagePills />
                </div>
                {summary && (
                    <div
                        className="hero__summary"
                        dangerouslySetInnerHTML={{__html: summary}}
                    />
                )}
                {highlights && (
                    <div className="hero__highlights">
                        <WhatYouWillLearn highlights={highlights} />
                    </div>
                )}
            </div>
            <style jsx>
                {`
                    h1 {
                        margin-bottom: 8px;
                    }
                    .hero {
                        background: linear-gradient(
                            359.75deg,
                            rgba(229, 235, 245, 0.6) 0.19%,
                            rgba(239, 246, 247, 0.6) 97.73%
                        );
                        padding: 24px 15px 40px 15px;
                        margin-bottom: 40px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .hero {
                            padding: 24px 0 40px 0;
                        }
                    }
                    .hero__inner {
                        max-width: ${MAX_WIDTH_PX};
                        margin: 0 auto;
                    }
                    .hero__readtime {
                        font-size: 12px;
                        color: var(--gray-400);
                    }
                    .hero__pills {
                        margin-top: 16px;
                    }
                    .hero__summary {
                        margin-bottom: 16px;
                        line-height: 25px;
                    }
                `}
            </style>
        </div>
    );
};
export default ArticleHero;

const BreadcrumbsStyles = styled.div`
    .breadcrumbs {
        margin: 0 0 8px 0;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        .breadcrumbs {
            margin: 0 0 48px 0;
        }
    }
`;
