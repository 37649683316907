import {BREAKPOINT} from '../../lib/styles';

const Sidebar = (props) => {
    return (
        <aside id="sidebar" style={props.style}>
            <div>{props.children}</div>
        </aside>
    );
};
export default Sidebar;
