export const ctaCopy = {
    'adcare-hospital': {
        id: 'AHW',
        slug: 'adcare-hospital',
        location: '107 Lincoln Street Worcester, MA 01605',
        highlights:
            '<p>AdCare Treatment Hospital offers multiple levels of care to people needing rehabilitation in Massachusetts, including detoxification and outpatient programs. AdCare can help you or your loved one recover from <a href="/co-occurring-disorders" title="co-occurring">co-occurring</a> substance use and mental health disorders. Our facility is equipped to handle a variety of medical issues on top of treating substance use and chemical dependence.</p>',
        services: [
            'Medication-Assisted Treatment (MAT)',
            'Safe Medical Detox',
            'Co-Occurring Mental Health Disorder Treatment',
            'Individual & Group Therapy',
        ],
        payment:
            '<p>The cost of addiction treatment varies based on a variety of factors and can vary based on individual needs.</p><p>American Addiction Centers (AAC), a leading provider of addiction treatment services across the U.S. AAC works to make treatment accessible for everyone and offers free and confidential verification of insurance plan benefits to help you determine anticipated costs and coverage.</p><p>AAC accepts many different insurance plans and we frequently accept many big and small name insurance providers from Blue Cross Blue Shield, Aetna, Anthem, Cigna, Humana, TRICARE, United Health & more.</p><p>Let us remove the confusion and difficulty of verifying your insurance coverage. We have years of experience in the addiction space and contracts with many of the big name insurance providers. By providing your name, contact information, and insurance provider, we can communicate directly with your insurance provider to find out if you are in-network with our facilities, the length of stay covered, and more without the hassle of having you contact them directly.</p>',
        statistics: [
            'We have been helping patients overcome addiction for 45+ years',
            'American Addiction Centers’ admissions hotline has been called over 133,000 times in the past year',
            'Over 6,000 people have verified their insurance using our site form since 2020, and over 80% have had their policy accepted at one of our facilities',
            'Contact us to learn why people choose American Addiction Centers for treatment',
        ],
        rehab_guide: '/rehab-guide/reasons-choose-adcare-hospital',
    },
    'recovery-first-hollywood': {
        id: 'REF',
        slug: 'recovery-first-hollywood',
        location: '4110 Davie Road Extension Hollywood, FL 33024',
        highlights:
            '<p>Recovery First Treatment Center provides multiple levels of care for those struggling with addiction. Located in Hollywood, Florida, just a 30 minute drive from downtown Miami, Recovery First specializes in treating those with addiction to drugs and alcohol and is also equipped to treat those with co-occurring disorders.</p>',
        services: [
            'Medication-Assisted Treatment (MAT)',
            'Safe Medical Detox',
            'Inpatient & Live-in Rehab',
            'Co-Occurring Mental Health Disorder Treatment',
        ],
        payment:
            '<p>American Addiction Centers (AAC), a leading provider of addiction treatment services across the U.S. AAC works to make treatment accessible for everyone and offers free and confidential verification of insurance plan benefits to help you determine anticipated costs and coverage.</p><p>AAC accepts many different insurance plans and we frequently accept many big and small name insurance providers from Blue Cross Blue Shield, Aetna, Anthem, Cigna, Humana, <a href="/insurance-coverage/new-directions>New Directions</a>, TRICARE, United Health & more.</p><p>Let us remove the confusion and difficulty of verifying your insurance coverage. We have years of experience in the addiction space and contracts with many of the big name insurance providers. By providing your name, contact information, and insurance provider, we can communicate directly with your insurance provider to find out if you are in-network with our facilities, the length of stay covered, and more without the hassle of having you contact them directly.</p>',
        statistics: [
            'Recovery First Treatment Center has helped treat over 7,000 patients with addiction',
            'American Addiction Centers’ admissions hotline has been called over 133,000 times in the past year',
            'Over 6,000 people have verified their insurance using our site form since 2020, and over 80% have had their policy accepted at one of our facilities',
            'Contact us to learn why people choose American Addiction Centers for treatment',
        ],
        rehab_guide: '/rehab-guide/reasons-choose-recovery-first ',
    },
    'river-oaks': {
        id: 'ROA',
        slug: 'river-oaks',
        location: '12012 Boyette Road Riverview, FL 33569',
        highlights:
            '<p>River Oaks sits on a 26-acre plot of secluded land near Tampa, Florida. River Oaks offers drug and addiction treatment services including detox, <a href="/rehab-guide/residential">long term rehab</a> or residential treatment, <a href="/rehab-guide/outpatient-treatment">partial hospitalization program</a>, and other outpatient programs to those suffering from addiction and other co-occurring disorders. Just a 24-minute drive from downtown Tampa, River Oaks is an ideal place to recover and connect with sober peers, and patients can choose from various <a href="/rehab-guide/evidence-based">evidence-based addiction therapies</a> to suit their personalized needs and goals.</p>',
        services: [
            'Medication for addiction treatment',
            'Safe medical detox',
            'Inpatient and live-in rehab',
            'Co-occurring mental health disorder treatment',
            'Individual and group therapy',
        ],
        payment:
            '<p>The cost of addiction treatment varies based on a variety of factors and can vary based on individual needs.</p><p>American Addiction Centers (AAC), a leading provider of addiction treatment services across the United States, works to make treatment accessible for everyone and offers free and confidential verification of insurance plan benefits to help you determine anticipated costs and coverage.</p><p>AAC accepts many different insurance plans, and we frequently accept many big and small name insurance providers from Blue Cross Blue Shield, Aetna, Anthem, Cigna, Humana, TRICARE, United Health, and more.</p><p>Let us remove the confusion and difficulty of verifying your insurance coverage. We have years of experience in the addiction space and contracts with many of the big-name insurance providers. By providing your name, contact information, and insurance provider, we can communicate directly with your insurance provider to find out if you are in-network with our facilities, the length of stay covered, and more without the hassle of having you contact them directly.</p>',
        statistics: [
            'River Oaks Treatment Center has helped treat over 8,000 patients with addiction',
            'American Addiction Centers’ admissions hotline has been called over 133,000 times in the past year',
            'Over 6,000 people have verified their insurance using our site form since 2020, and over 80% have had their policy accepted at one of our facilities',
            'Contact us to learn why people choose American Addiction Centers for treatment',
        ],
        rehab_guide: '/rehab-guide/reasons-choose-river-oaks',
    },
    'laguna-treatment-center': {
        id: 'LAG',
        slug: 'laguna-treatment-center',
        location: '24552 Pacific Park Drive Aliso Viejo, CA 92656',
        highlights:
            '<p>Laguna Treatment Center also offers co-occurring disorder rehab treatment and intensive live-in rehabilitation for those suffering from substance use disorders and other co-occurring mental health conditions in California.</p>',
        services: [
            'Medication-Assisted Treatment (MAT)',
            'Safe Medical Detox',
            'Intensive Medical Rehab',
            'Live-in Rehabilitation',
            'Translation Services (Spanish 24 Hours a Day & Others With Notice)',
        ],
        payment:
            '<p>The cost of addiction treatment varies based on a variety of factors and can vary based on individual needs.</p><p>American Addiction Centers (AAC), a leading provider of addiction treatment services across the U.S. AAC works to make treatment accessible for everyone and offers free and confidential verification of insurance plan benefits to help you determine anticipated costs and coverage.</p><p>AAC accepts many different insurance plans and can work with you on creating a manageable payment plan to fund your treatment stay at Laguna Treatment Center.</p><p>Let us remove the confusion and difficulty of verifying your insurance coverage. We have years of experience in the addiction space and contracts with many of the big name insurance providers. By providing your name, contact information, and insurance provider, we can communicate directly with your insurance provider to find out if you are in-network with our facilities, the length of stay covered, and more without the hassle of having you contact them directly.</p>',
        statistics: [
            'Laguna Treatment Center has helped treat over 8,000 patients with addiction.',
            'American Addiction Centers’ admissions hotline has been called over 133,000 times in the past year.',
            'Over 6,000 people have verified their insurance using our site form since 2020, and over 80% have had their policy accepted at one of our facilities.',
            'Contact us to learn why people choose American Addiction Centers for treatment.',
        ],
        rehab_guide: '/rehab-guide/reasons-choose-laguna',
    },
    greenhouse: {
        id: 'GRN',
        slug: 'greenhouse',
        location: '1171 107th St Grand Prairie, TX 75050',
        highlights:
            '<p>Greenhouse Treatment Center is our drug and alcohol rehab facility in Texas. Greenhouse is accredited by the Commission on Accreditation of Rehabilitation Facilities (CARF), the Texas Department of State Health Services, and a member of the National Association for Behavioral Healthcare.</p>',
        services: [
            'Medication-Assisted Treatment (MAT)',
            'Safe Medical Detox',
            'Inpatient & Live-in Rehab',
            'Outpatient Programming',
            'Co-Occurring Mental Health Disorder Treatment',
            'Individual & Group Therapy',
        ],
        payment:
            '<p>The cost of addiction treatment varies based on a variety of factors and can vary based on individual needs.</p><p>American Addiction Centers (AAC), a leading provider of addiction treatment services across the U.S. AAC works to make treatment accessible for everyone and offers free and confidential verification of insurance plan benefits to help you determine anticipated costs and coverage.</p><p>AAC accepts many different insurance plans and we frequently accept many big and small name insurance providers from Blue Cross Blue Shield, Aetna, Anthem, Cigna, Humana, TRICARE, United Health & more.</p><p>Let us remove the confusion and difficulty of verifying your insurance coverage. We have years of experience in the addiction space and contracts with many of the big name insurance providers. By providing your name, contact information, and insurance provider, we can communicate directly with your insurance provider to find out if you are in-network with our facilities, the length of stay covered, and more without the hassle of having you contact them directly.</p>',
        statistics: [
            'Greenhouse Treatment Center has helped treat over 11,700 patients with addiction.',
            'American Addiction Centers’ admissions hotline has been called over 133,000 times in the past year.',
            'Over 6,000 people have verified their insurance using our site form since 2020, and over 80% have had their policy accepted at one of our facilities.',
            'Contact us to learn why people choose American Addiction Centers for treatment.',
        ],
        rehab_guide: '/rehab-guide/reasons-choose-greenhouse',
    },
    'desert-hope': {
        id: 'DSH',
        slug: 'desert-hope',
        location: '2465 East Twain Avenue Las Vegas, NV 89121',
        highlights:
            '<p>Desert Hope Treatment Center is a Joint Commission-accredited addiction treatment facility offering a medically supervised detox program, <a href="/rehab-guide/inpatient">inpatient rehab center</a>, outpatient care, and sober living residences. Located in Las Vegas, this rehab center offers a destination treatment center with comprehensive detox and inpatient rehab center programs to fit your personal needs.</p>',
        services: [
            'Medication-Assisted Treatment (MAT)',
            'Safe Medical Detox',
            'Inpatient & Live-in Rehab',
            '<a href="/co-occurring-disorders">Co-occurring disorder mental health treatment</a>',
            'Individual & Group Therapy',
        ],
        payment:
            '<p>The cost of addiction treatment varies based on a variety of factors and can vary based on individual needs.</p><p>American Addiction Centers (AAC), a leading provider of addiction treatment services across the U.S. AAC works to make treatment accessible for everyone and offers free and confidential verification of insurance plan benefits to help you determine anticipated costs and coverage.</p><p>AAC accepts many different insurance plans and we frequently accept many big and small name insurance providers from Blue Cross Blue Shield, Aetna, Elevance, Cigna, Humana, TRICARE, United Health & more.</p><p>Let us remove the confusion and difficulty of verifying your insurance coverage. We have years of experience in the addiction space and contracts with many of the big name insurance providers. By providing your name, contact information, and insurance provider, we can communicate directly with your insurance provider to find out if you are in-network with our facilities, the length of stay covered, and more without the hassle of having you contact them directly.</p>',
        statistics: [
            'Desert Hope has helped over treat over 13,000 patients with addiction.',
            'American Addiction Centers’ admissions hotline has been called over 133,000 times in the past year.',
            'Over 6,000 people have verified their insurance using our site form since 2020, and over 80% have had their policy accepted at one of our facilities.',
            'Contact us to learn why people choose American Addiction Centers for treatment.',
        ],
        rehab_guide: '/rehab-guide/reasons-choose-desert-hope',
    },
    'adcare-rhode-island': {
        id: 'ARI',
        slug: 'adcare-rhode-island',
        location: '1950 Tower Hill Road North Kingstown, RI 02852',
        highlights:
            '<p>AdCare Rhode Island is a licensed and accredited rehab facility offering a full continuum of addiction care options in Rhode Island. AdCare Rhode Island is fully equipped to treat those with <a href="/co-occurring-disorders">co-occurring disorders</a>, veterans, people in the LGBTQ community, first responders, and more.</p><p>AdCare offers multiple level of care to people needing rehabilitation in Rhode Island, including detoxification, inpatient rehab, outpatient programs, and more.</p>',
        services: [
            '<a href="/rehab-guide/inpatient">Inpatient rehab</a> and <a href="/rehab-guide/outpatient-treatment">outpatient drug rehab</a>',
            'Individual and group therapies',
            'Medication-Assisted Treatment (MAT)',
            'Co-occurring disorder treatment',
            'Live-in rehabilitation',
            'Aftercare planning and access to our alumni network',
            'Translation services (Spanish 24 hours a day & other languages with notice)',
        ],
        payment:
            '<p>The cost of addiction treatment varies based on a variety of factors and can vary based on individual needs.</p><p>American Addiction Centers (AAC), a leading provider of addiction treatment services across the U.S. AAC works to make treatment accessible for everyone and offers free and confidential verification of insurance plan benefits to help you determine anticipated costs and coverage</p><p>AAC accepts many different insurance plans and we frequently accept many big and small name insurance providers from Blue Cross Blue Shield, Aetna, Anthem, Cigna, Humana, TRICARE, United Health & more.</p><p>Let us remove the confusion and difficulty of verifying your insurance coverage. We have years of experience in the addiction space and contracts with many of the big name insurance providers. By providing your name, contact information, and insurance provider, we can communicate directly with your insurance provider to find out if you are in-network with our facilities, the length of stay covered, and more without the hassle of having you contact them directly.</p>',
        statistics: [
            'We have been helping patients overcome addiction for 45+ years.',
            'American Addiction Centers’ admissions hotline has been called over 133,000 times in the past year.',
            'Over 6,000 people have verified their insurance using our site form since 2020, and over 80% have had their policy accepted at one of our facilities.',
            'Contact us to learn why people choose American Addiction Centers for treatment.',
        ],
        rehab_guide: '/treatment-centers/adcare-rhode-island',
    },
    oxford: {
        id: 'XFO',
        slug: 'oxford',
        location: '297 County Road 244 Etta, MS 38627',
        highlights:
            '<p>Oxford Treatment Center is located in the remote and rolling hills of Northern Mississippi. It possesses breathtaking views in a comfortable, safe, and secluded setting. We are open and accepting new patients during the <a href="/covid-19-update">COVID-19 (aka coronavirus) pandemic</a>. Oxford Treatment Center puts clients at ease and helps them reconnect with nature, themselves, and peers during their recovery.</p>',
        services: [
            'Medication-Assisted Treatment (MAT)',
            'Safe Medical Detox',
            'Inpatient & Live-in Rehab',
            'Co-Occurring Mental Health Disorder Treatment',
            'Individual & Group Therapy',
        ],
        payment:
            '<p>The cost of addiction treatment varies based on a variety of factors and can vary based on individual needs.</p><p>American Addiction Centers (AAC), a leading provider of addiction treatment services across the U.S. AAC works to make treatment accessible for everyone and offers free and confidential verification of insurance plan benefits to help you determine anticipated costs and coverage.</p><p>AAC accepts many different insurance plans and we frequently accept many big and small name insurance providers from Blue Cross Blue Shield, Aetna, Anthem, Cigna, Humana, TRICARE, United Health & more.</p><p>Let us remove the confusion and difficulty of verifying your insurance coverage. We have years of experience in the addiction space and contracts with many of the big name insurance providers. By providing your name, contact information, and insurance provider, we can communicate directly with your insurance provider to find out if you are in-network with our facilities, the length of stay covered, and more without the hassle of having you contact them directly.</p>',
        statistics: [
            'Oxford Treatment Center has helped treat over 8,000 patients with addiction.',
            'American Addiction Centers’ admissions hotline has been called over 133,000 times in the past year.',
            'Over 6,000 people have verified their insurance using our site form since 2020, and over 80% have had their policy accepted at one of our facilities.',
            'Contact us to learn why people choose American Addiction Centers for treatment.',
        ],
        rehab_guide: '/rehab-guide/reasons-choose-oxford',
    },
};

export const ctaPaths = [
    {
        pathname: '/alabama',
        facility: ['oxford'],
    },
    {
        pathname: '/arizona',
        facility: ['desert-hope', 'laguna-treatment-center'],
    },
    {
        pathname: '/arkansas',
        facility: ['oxford'],
    },
    {
        pathname: '/california',
        facility: ['laguna-treatment-center'],
    },
    {
        pathname: '/florida',
        facility: ['river-oaks', 'recovery-first-hollywood'],
    },
    {
        pathname: '/florida/jacksonville',
        facility: ['river-oaks'],
    },
    {
        pathname: '/florida/miami',
        facility: ['recovery-first-hollywood'],
    },
    {
        pathname: '/florida/orlando',
        facility: ['river-oaks'],
    },
    {
        pathname: '/florida/tampa',
        facility: ['river-oaks'],
    },
    {
        pathname: '/georgia',
        facility: ['recovery-first-hollywood'],
    },
    {
        pathname: '/louisiana',
        facility: ['oxford', 'greenhouse'],
    },
    {
        pathname: '/massachusetts',
        facility: ['adcare-hospital'],
    },
    {
        pathname: '/mississippi',
        facility: ['oxford'],
    },
    {
        pathname: '/nevada',
        facility: ['desert-hope'],
    },
    {
        pathname: '/oregon',
        facility: ['laguna-treatment-center'],
    },
    {
        pathname: '/rhode-island',
        facility: ['adcare-rhode-island'],
    },
    {
        pathname: '/tennessee',
        facility: ['oxford'],
    },
    {
        pathname: '/texas',
        facility: ['greenhouse'],
    },
    {
        pathname: '/utah',
        facility: ['desert-hope'],
    },
];
