import {BREAKPOINT} from 'aac-components/utils/styles';
import React, {useContext} from 'react';
import {getSegmentNumber, getTags} from '../../../lib/utils';
import AppContext from '../../AppContext';
import {replaceDynamicKeywords} from '../../../lib/dynamic-keywords';
import Button from 'aac-components/components/Button';
import CallrailButton from '../../CallrailButton';
import styled from 'styled-components';
import DriftContext from '../../Drift/DriftContext';
import {launchChat} from '../../Drift/helpers';

const TertiaryCta = (props) => {
    const segmentNumber = getSegmentNumber();
    const {
        acfOptions: {acf: {tertiary_cta = []} = {}} = {},
        dynamicKeywords = {},
        pageProps: {data: {acf: {content_segment = ''} = {}} = {}} = {},
    } = useContext(AppContext);

    const {driftLoaded} = useContext(DriftContext);

    const ctasForSegment =
        Array.isArray(tertiary_cta) &&
        tertiary_cta?.filter((cta) => cta?.segments?.includes(segmentNumber?.toString()));

    if (!ctasForSegment) return null;

    const renderButton = (button_type, button_text, button_href) => {
        switch (button_type) {
            case 'vob_slideout':
                return (
                    <Button onClick={(e) => window && window.AAC.openSlideInSsvob(e)}>
                        {button_text}
                    </Button>
                );
            case 'aktify_slideout':
                return (
                    <Button onClick={() => window && window.AAC.openSlideInSms()}>
                        {button_text}
                    </Button>
                );
            case 'chat':
                return (
                    <>
                        {driftLoaded && (
                            <Button onClick={launchChat}>Chat With Us</Button>
                        )}
                    </>
                );
            case 'custom':
                return <Button href={button_href}>{button_text}</Button>;
            default:
                return <CallrailButton style={{width: '100%'}} />;
        }
    };

    const tags = getTags();

    const useWithdrawlCopy =
        tags?.includes('withdrawal') && content_segment !== 'info-drug-nodetox';

    return (
        <div id="tertiary-cta" className="tertiary-cta__copy">
            {useWithdrawlCopy ? (
                <div className="tertiary-cta">
                    <div className="tertiary-cta__title text-lg bold">
                        Are You or a Loved One Suffering Right Now?
                    </div>
                    <p>
                        Contact us to learn more about medically managed detox, which can
                        ease withdrawal symptoms and ensure you’re safe and comfortable
                        during the detox process.
                    </p>
                    <div className="tertiary-cta__button">
                        <CallrailButton
                            text="Talk to Someone About Detox"
                            style={{width: '100%'}}
                        />
                    </div>
                </div>
            ) : (
                Array.isArray(ctasForSegment) &&
                ctasForSegment.map((cta, index) => {
                    const {title, copy, button_type, button_text, button_href} = cta;

                    return (
                        <div key={`${cta?.title} - ${index}`} className="tertiary-cta">
                            <div
                                className="tertiary-cta__title text-lg bold"
                                dangerouslySetInnerHTML={{
                                    __html: replaceDynamicKeywords(
                                        title,
                                        dynamicKeywords,
                                    ),
                                }}
                            />
                            <p
                                className="tertiary-cta__copy"
                                dangerouslySetInnerHTML={{
                                    __html: replaceDynamicKeywords(copy, dynamicKeywords),
                                }}
                            />
                            <div className="tertiary-cta__button">
                                <ButtonStyles>
                                    {renderButton(
                                        button_type,
                                        replaceDynamicKeywords(
                                            button_text,
                                            dynamicKeywords,
                                        ),
                                        button_href,
                                    )}
                                </ButtonStyles>
                            </div>
                        </div>
                    );
                })
            )}
            <style jsx>{`
                .tertiary-cta {
                    background: var(--secondary-100);
                    border: 1px solid var(--secondary-300);
                    border-radius: 8px;
                    padding: 40px 16px;
                    text-align: center;
                    margin: 30px 0;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .tertiary-cta {
                        padding: 32px;
                        margin: 60px 0;
                    }
                }
                .tertiary-cta__button {
                    margin: 0 auto;
                    width: 100%;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .tertiary-cta__button {
                        max-width: fit-content;
                    }
                }
            `}</style>
        </div>
    );
};

export default TertiaryCta;

const ButtonStyles = styled.div`
    button,
    a {
        min-width: 100%;
        font-weight: normal;
        text-transform: capitalize;
    }
`;
