import {getContributorData} from 'aac-components/components/Byline/helpers';
import Image from 'next/image';
import IconUser from 'aac-components/components/Icons/User/IconUser';
import Link from 'next/link';
import {BREAKPOINT} from 'aac-components/utils/styles';
import Tooltip from 'aac-components/components/Tooltip';
import IconInfo from 'aac-components/components/Icons/Attention/IconInfo';

const ContributorCard = ({byline_info}) => {
    // Hierarchy: author -> reviewer -> editor
    let cardData =
        byline_info?.author || byline_info?.reviewer || byline_info?.editor || [];
    cardData = getContributorData(cardData) || {};

    const contributorType = byline_info?.author
        ? 'Author'
        : byline_info?.reviewer
        ? 'Medical Reviewer'
        : 'Editor';

    const {
        name = 'Editorial Staff',
        link = '/contributors/editorial-staff',
        image = '',
        job_title = '',
        post_excerpt = '',
    } = cardData;

    const hasLongExcerpt = post_excerpt?.length >= 150;
    const copy = hasLongExcerpt
        ? `${post_excerpt.slice(0, 150 - 1)} &hellip;`
        : post_excerpt;

    return (
        <div className="contributor-card">
            <div className="contributor-card__title text-2xl bold">
                About The Contributor
            </div>
            <div className="container">
                <div className="contributor-card__img">
                    {image ? (
                        <Image
                            src={image}
                            alt={name}
                            fill
                            style={{objectFit: 'cover'}}
                            sizes="120px"
                        />
                    ) : (
                        <IconUser
                            fill="var(--gray-100, #f8f8f8)"
                            width="64"
                            height="64"
                        />
                    )}
                </div>
                <div>
                    <a
                        href={link}
                        title={name}
                        className="contributor-card__name"
                        dangerouslySetInnerHTML={{__html: name}}
                    />
                    <div
                        className="contributor-card__job-title"
                        dangerouslySetInnerHTML={{
                            __html: `${
                                job_title || contributorType
                            }, American Addiction Centers`,
                        }}
                    />
                    <div className="contributor-card__excerpt">
                        <span dangerouslySetInnerHTML={{__html: copy}} />{' '}
                        {hasLongExcerpt && (
                            <Link href={link} legacyBehavior>
                                <a>Read More</a>
                            </Link>
                        )}
                    </div>
                    <div className="contributor-card__editorial-policies">
                        <div>
                            <Link href="/about-us/editorial-policy" legacyBehavior>
                                <a title="Read Our Editorial Policy">
                                    Read Our Editorial Policy
                                </a>
                            </Link>
                        </div>
                        <Tooltip
                            distance={0}
                            tagName="span"
                            tooltipContent={
                                <div style={{fontSize: '12px'}}>
                                    American Addiction Centers (AAC) is committed to
                                    delivering original, truthful, accurate, unbiased, and
                                    medically current information. We strive to create
                                    content that is clear, concise, and easy to
                                    understand. Our content is written, edited, and
                                    published by industry experts and experienced
                                    editorial staff. During the editorial and medical
                                    review process, our team fact checks information and
                                    ensures that only reputable, credible, and current
                                    sources get used. Click the link to learn more about
                                    our editorial policy.
                                </div>
                            }
                            renderChildren={() => (
                                <IconInfo
                                    fill={'var(--interactive-300, #2459A9)'}
                                    width="18"
                                    height="18"
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
            <style jsx>
                {`
                    .contributor-card {
                        border-top: 2px solid var(--gray-300);
                        padding-top: 24px;
                        margin-bottom: 24px;
                    }
                    .contributor-card__title {
                        margin-bottom: 24px;
                    }
                    .contributor-card__img {
                        position: relative;
                        width: 120px;
                        height: 120px;
                        border-radius: 8px;
                        overflow: hidden;
                    }
                    .contributor-card__name {
                        font-size: 20px;
                        display: block;
                        margin-bottom: 4px;
                        color: var(--gray-500);
                        text-decoration: none;
                    }
                    .contributor-card__job-title {
                        font-size: 14px;
                        font-weight: bold;
                        margin-bottom: 16px;
                    }
                    .contributor-card__excerpt {
                        font-size: 12px;
                        margin-bottom: 16px;
                    }
                    .contributor-card__editorial-policies {
                        font-size: 14px;
                        font-weight: bold;
                        display: flex;
                        grid-gap: 4px;
                    }
                    .contributor-card__editorial-policies a {
                        text-decoration: none;
                    }
                    .container {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 24px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .container {
                            grid-template-columns: 120px auto;
                            grid-gap: 40px;
                        }
                    }
                `}
            </style>
        </div>
    );
};
export default ContributorCard;
