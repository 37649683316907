import {useRouter} from 'next/dist/client/router';
import React, {useContext, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import FacilityCta from './FacilityCta';
import TertiaryCta from './TertiaryCta';
import FacilitySpotlightCta from './FacilitySpotlightCta';
import {isSpanishPage, getSlug} from '../../lib/utils';
import AppContext from '../AppContext';
import FacilityCardSlider from '../FacilityCardSlider';
import {BREAKPOINT} from 'aac-components/utils/styles';

const InContentCta = ({type}) => {
    const {asPath} = useRouter();
    const lang = isSpanishPage(getSlug(asPath)) ? 'es' : 'en';

    const appContext = useContext(AppContext) || {};
    const {sessionStorageState: {geolocation: {facility = ''} = {}} = {}} = appContext;

    const [element, setElement] = useState(null);

    const isOutpatient = asPath?.includes('outpatient');
    const FacCtaComponent = isOutpatient ? OutpatientFacCardSlider : FacilityCta;

    const CTA = () => {
        const isLocalPage = asPath?.includes('/treatment-centers');

        if (type === 'tertiary') {
            // above 3rd h2
            return isLocalPage ? <FacilitySpotlightCta /> : <TertiaryCta />;
        } else {
            // above 4th h2
            return !isLocalPage && !!facility ? (
                <FacilitySpotlightCta facility={facility} />
            ) : (
                <FacCtaComponent />
            );
        }
    };

    const id = type === 'tertiary' ? 'content-cta__tertiary' : 'content-cta__facilities';

    let position = type === 'tertiary' ? 2 : 3;

    const appendCta = () => {
        const newDiv = document.createElement('div');

        // don't add a new div if already on the page
        newDiv.id = id;
        const ctas = document.querySelectorAll(`#${id}`);
        if (ctas.length > 0) return;

        const elements = Array.from(
            document.querySelectorAll('div:not(.modal-content) > h2'),
        );

        // do not render the facility map if lenght of h2's is less than 3
        if (id === 'content-cta__facilities' && elements.length < 3) {
            return;
        }

        // for tertiary cta, add above end h2 if there's less than 3
        position = elements?.length <= position && type === 'tertiary' ? 1 : position;

        if (elements.length > position) {
            // Append cta in the middle of content
            elements[position]?.before(newDiv);
        } else {
            // else append at the end of the content
            const articleElement = document.getElementById('wordpress-content');
            articleElement.appendChild(newDiv);
        }

        setElement(newDiv);
    };
    useEffect(() => {
        appendCta();
    }, [asPath]);

    return (
        <div>{element && ReactDOM.createPortal(<CTA asPath={asPath} />, element)}</div>
    );
};
export default React.memo(InContentCta);

const OutpatientFacCardSlider = () => {
    return (
        <div className="container">
            <h2>Our Outpatient Facilities Nationwide</h2>
            <FacilityCardSlider isOutpatient={true} />
            <style jsx>
                {`
                    .container {
                        margin: 40px 0;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .container {
                            margin: 80px 0;
                        }
                    }
                    h2 {
                        text-align: center;
                    }
                `}
            </style>
        </div>
    );
};
